<template>
  <a-drawer
    :placement="drawerPlacement"
    :visible="visibleDrawer"
    class="topic-management-drawer"
    :class="dynamicClass + openiscussionCollapse"
    width="46.5rem"
    title="Topic Management"
    @close="closeTopicManagementDrawer()"
  >
    <div class="topic-management">
      <div class="topic-management__body">
        <div class="skeleton-loader" v-if="loader">
            <a-skeleton
              active
              :title="null"
              :paragraph="{ rows: 1, width: ['100%'] }"
              class="input-skeleton"
            />
            <template v-for="skeleton in 3" :key="skeleton">
              <a-skeleton
                active
                :title="null"
                :paragraph="{ rows: 1, width: ['100%'] }"
                class="input-skeleton"
              />
              <a-skeleton
                active
                :title="null"
                :paragraph="{ rows: 3, width: ['23%', '23%', '23%'] }"
                class="user-card-skeleton"
              />
            </template>
        </div>
        <topic-management 
          v-else
          :topicToBeManage="topicToBeManage">
        </topic-management>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import TopicManagement from "./TopicManagement.vue";

export default {
  props: {
    visibleDrawer: Boolean,
    drawerPlacement: String,
    topicToBeManage: Object,
  },
  components: {
    TopicManagement,
  },
  watch: {
    drawerPlacement(val) {
      if (val === "bottom") {
        this.dynamicClass = "topic-management-drawer-mobile-view";
      } else {
        this.dynamicClass = "";
      }
    },
    visibleDrawer(val) {
      if (val) {
        this.getTopicGroupDetaiils();
      }
    },
  },
  mounted() {
    this.emitter.on('openCollapseSection', (val) => {
      val ? this.openiscussionCollapse = ' pb-iscussion-collapse-opened' : this.openiscussionCollapse = '';
  });
  },
  data() {
    return {
      activeKey: "1",
      dynamicClass: "",
      loader: true,
      openiscussionCollapse: ""
    };
  },
  computed: {
    interestGroupAudience() {
      var value = "";
      this.$store.getters["article/getInterestGroup"].forEach((element) => {
        value = element.is_private;
      });
      return value;
      },
  },
  methods: {
    closeTopicManagementDrawer() {
      this.$emit("close-management-drawer", false);
    },
    async getTopicGroupDetaiils() {
      this.loader = true;
      const payload = {
        interest_id: this.topicToBeManage.id,
        is_private: this.interestGroupAudience,
      };
      const response = await this.$store.dispatch(
        "article/topicGroupDetails",
        payload
      );
      if (response.status == 200) {
        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss">
.topic-management-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      overflow: hidden;
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header {
          padding: 3.3rem 2.5rem 2.4rem;
          border: none;
          .ant-drawer-title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            opacity: 1;
            color: $color-black;
          }
          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 3.3rem;
            color: $color-black;
            opacity: 1;
          }
        }
        .ant-drawer-body {
          height: 100%;
          padding: 0;
          overflow: hidden;
          .topic-management {
            position: relative;
            height: 100vh;
            &__body {
              height: 100%;
              .skeleton-loader{
                margin: 0 2.4rem;
                overflow-y: auto;
                height: calc(100vh - 12rem);
                .input-skeleton {
                  margin-bottom: 2rem;
                  .ant-skeleton-content{
                    .ant-skeleton-paragraph {
                      margin: 0;
                      li {
                        height: 4rem;
                      }
                    }
                  }
                }
                .user-card-skeleton {
                  margin-bottom: 3rem;
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      display: flex;
                      align-items: center;
                      gap: 2rem;
                      li {
                        height: 10rem;
                        margin: 0;
                      }
                    }
                  }
                }
                &::-webkit-scrollbar-track {
                  background-color: #ffffff3f;
                }
                &::-webkit-scrollbar {
                  width: 4px;
                  background-color: rgb(224, 224, 224);
                  border-radius: 3rem;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: $color-primary;
                  border-radius: 3rem;
                }
              } 
            }
          }
        }
      }
    }
  }
}
.add-new-topic-public-private-dropdown {
  @include respond(phone-x-small) {
    padding: 0;
    font-size: 2.1rem;
    line-height: 3.5rem;
    .image {
      width: 2.8rem !important;
      height: auto;
      margin-right: 1.5rem !important;
    }
  }
}
.topic-management-drawer-mobile-view {
  height: 77vh !important;
  .ant-drawer-content-wrapper {
    height: 100% !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          padding-bottom: 3rem;
          .ant-drawer-title {
            font-size: 3.1rem;
          }
        }
        .ant-drawer-body {
          .topic-management {
            &__body {
              .tab-management-container {
                height: calc(100vh - 32.5rem);
                @include respond(phone-x-small) {
                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
                &__section {
                  &--header {
                    margin-bottom: 2.5rem;
                    .title {
                      font-size: 2.3rem;
                    }
                    .btn {
                      font-size: 1.8rem;
                      width: 17rem;
                      height: 5rem;
                      .icon {
                        font-size: 1.5rem;
                      }
                    }
                  }
                  &--input {
                    font-size: 2.1rem;
                    min-height: 6.2rem;
                    padding: 0 2rem;
                  }
                  .privacy-settings {
                    &__header {
                      &--name {
                        img {
                          width: 2.5rem;
                          margin-top: -0.5rem;
                        }
                        p {
                          font-size: 2.3rem;
                        }
                      }
                      &--select {
                        .ant-select-selector {
                          min-width: 16rem;
                          height: 5rem !important;
                          .ant-select-selection-item {
                            font-size: 1.8rem;
                            .select-item {
                              display: flex;
                              font-size: 1.8rem;
                              img {
                                margin-top: 0rem;
                              }
                              .white-icon {
                                display: block !important;
                                width: 1.8rem !important;
                              }
                              .black-icon {
                                display: none !important;
                              }
                            }
                          }
                        }
                        .ant-select-arrow {
                          .anticon {
                            font-size: 1.5rem;
                            svg {
                              fill: $color-white;
                            }
                          }
                        }
                      }

                    }
                    &__body {
                      margin-top: 2rem;
                      p {
                        font-size: 1.8rem;
                        line-height: 2.5rem;
                      }
                    }
                  }
                  &--search-user {
                    height: 6.2rem;
                    .tagify {
                      min-height: 6.2rem;
                      padding: 0 2rem;
                      &__input {
                        font-size: 2.1rem;
                        height: 6.2rem;
                        padding: 1.8rem 0;
                      }
                    }
                    .btn {
                      font-size: 1.8rem;
                      width: 22rem;
                      height: 4.4rem;
                      .icon {
                        font-size: 1.6rem;
                      }
                    }
                  }
                  &--active-admin {
                    .header {
                      h2 {
                        font-size: 2.3rem;
                      }
                    }
                    .body {
                      .message {
                        font-size: 2.3rem;
                      }
                    }
                  }
                }
                &__footer {
                  .apply-btn {
                    width: 100%;
                    height: 6.8rem;
                    font-size: 2.1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
